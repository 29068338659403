import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { Button } from "react-bootstrap";
import 'font-awesome/css/font-awesome.min.css';
import "./CalendarButton.css";

export default ({
  icon = { 'calendar-plus-o': 'left fa-lg' },
  label = "",
  linkToCalendar,
  className = "",
  disabled = false,
  event = {},
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled }
    {...props}
  >
    {linkToCalendar && <AddToCalendar buttonLabel={label} event={event} buttonTemplate={icon} optionsOpen={true}/>}
    {!linkToCalendar && <AddToCalendar buttonLabel={label} event={event} buttonTemplate={icon} optionsOpen={false}/>}
  </Button>;