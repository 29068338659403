import React from "react";
import { Button } from "react-bootstrap";
import { FiRefreshCw } from 'react-icons/fi';
import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  tile,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <FiRefreshCw size={21} className="refresh-spin"/>}
    {!isLoading ? tile : loadingText}
  </Button>;
