import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { HiOutlineMail } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri'
import { BiDonateHeart } from 'react-icons/bi'
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="Settings">
        <LinkContainer to="/settings/email">
          <LoaderButton 
            block bsSize="large" 
            text="Change Email" 
            tile={<HiOutlineMail size={30} className="change-email-tile"/>}
          />
        </LinkContainer>
        <LinkContainer to="/settings/password">
          <LoaderButton 
            block bsSize="large" 
            text="Change Password" 
            tile={<RiLockPasswordLine size={30} className="change-password-tile"/>}
          />
        </LinkContainer>
        <LinkContainer to="/settings/billing">
          <LoaderButton 
            block bsSize="large" 
            text="Donate" 
            tile={<BiDonateHeart size={30} className="donate-tile"/>}
          />
        </LinkContainer>
      </div>
    );
  }
}
