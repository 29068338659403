const dev = {
  STRIPE_KEY: "pk_test_I6tTE2ms08SDzwbHEx4Mu3eV00P0UZnqoD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "scratch-api-dev-attachmentsbucket-t318uy8ctyoj"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://2dajaniop6.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_9TqTeIwhH",
    APP_CLIENT_ID: "11rj0h8icgbpff3te0fhd2saed",
    IDENTITY_POOL_ID: "us-east-1:d4cbc82d-c051-42dc-8f81-6fb5d7ea3b6e"
  }
};

const test = {
  STRIPE_KEY: "pk_test_I6tTE2ms08SDzwbHEx4Mu3eV00P0UZnqoD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "scratch-api-test-attachmentsbucket-kqlkvueq6mlp"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ogmu72b7j0.execute-api.us-east-1.amazonaws.com/test"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Gf9oCaFGa",
    APP_CLIENT_ID: "6vofsmncb813tb5pn365hcvnk9",
    IDENTITY_POOL_ID: "us-east-1:a0480ef7-f357-4f2b-a587-15e61552edad"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_I6tTE2ms08SDzwbHEx4Mu3eV00P0UZnqoD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "scratch-api-prod-attachmentsbucket-1dd123a3x7kgn"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://37y59vf684.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_YlLj7rdTV",
    APP_CLIENT_ID: "106ut19simuh918hr2r0k023jt",
    IDENTITY_POOL_ID: "us-east-1:48226e70-57d3-4703-843c-68a92d664589"
  }
};

const config = {
  "dev": dev,
  "test": test,
  "prod": prod
}[process.env.REACT_APP_STAGE.trim()];

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
