import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { confirm } from "./components/Confirmation";
import { GoSignIn, GoSignOut } from 'react-icons/go';
import { FcDatabase, FcSettings } from 'react-icons/fc';
import CacheBuster from './CacheBuster';
import Routes from "./Routes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  async componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {

    if (
      await confirm(
        "Would you like to logout of all devices? " +
        "All devices will logout in 1-hour.",
        "Yes",
        "No"
      )
    ) {
        await Auth.signOut({ global: true });
    } 
    else {
      await Auth.signOut({ global: false });
    }

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <CacheBuster>
        {
          ({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }

            return (
              !this.state.isAuthenticating &&
              <div className="App container">
                <Navbar fluid collapseOnSelect>
                  <Navbar.Header>
                    <Navbar.Brand>
                      <Link to="/">
                        <FcDatabase size={30} className="ddb-tile"/>
                      </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                  </Navbar.Header>
                  <Navbar.Collapse>
                    <Nav pullRight>
                      {this.state.isAuthenticated
                        ? <Fragment>
                            <LinkContainer to="/settings">
                              <NavItem>
                                <FcSettings size={25} className="settings-tile"/>
                              </NavItem>
                            </LinkContainer>
                            <NavItem onClick={this.handleLogout}>
                              <GoSignOut size={25} className="sign-out-tile"/>
                            </NavItem>
                          </Fragment>
                        : <Fragment>
                            <LinkContainer to="/signup">
                              <NavItem>Signup</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/login">
                              <NavItem>
                                <GoSignIn size={25} className="sign-in-tile"/>
                              </NavItem>
                            </LinkContainer>
                          </Fragment>
                      }
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <Routes childProps={childProps} />
              </div>
            );
          }
        }
      </CacheBuster>
    );
  }
}

export default withRouter(App);
