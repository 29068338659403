import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { s3Upload, s3Delete } from "../libs/awsLib";
import { FcDeleteDatabase, FcDataBackup } from 'react-icons/fc';
import { confirm } from "../components/Confirmation";
import LoaderButton from "../components/LoaderButton";
import CalendarButton from "../components/CalendarButton";
// import ShareButton from "../components/ShareButton";
import config from "../config";
import "./Notes.css";


export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      note: null,
      content: "",
      attachmentURL: null,
      linkToCalendar: false,
      shareNote: false
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const note = await this.getNote();
      const { content, attachment } = note;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        note,
        content,
        attachmentURL
      });
    } catch (e) {
      alert(e);
    }
  }

  getNote() {
    return API.get("notes", `/notes/${this.props.match.params.id}`);
  }

  saveNote(note) {
    return API.put("notes", `/notes/${this.props.match.params.id}`, {
      body: note
    });
  }

  deleteNote() {
    return API.del("notes", `/notes/${this.props.match.params.id}`);
  }

  calendarEvent(note) {
    let event = {
      title: this.state.content.split('\n')[0],
      description: this.state.content,
      location: window.location.href
  };
    return event
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleLinkToCalendar = event => {
    this.setState({linkToCalendar: !this.state.linkToCalendar})
  }

  handleShareNote = event => {
    this.setState({shareNote: !this.state.shareNote})
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        if (this.state.note.attachment) {
          s3Delete(this.state.note.attachment)
        }
        attachment = await s3Upload(this.file);
        
      }

      if (await confirm(
        "Would you to move this note to the top of your list?",
        "Yes",
        "No"
        )
      ) {
        this.state.note.createdAt = Date.now();
      }

      await this.saveNote({
        createdAt: this.state.note.createdAt,
        content: this.state.content,
        attachment: attachment || this.state.note.attachment
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    if (await confirm(
      "Are you sure you want to delete this note?",
      "Yes",
      "No"
      )
    ) {
      this.setState({ isDeleting: true });
    } else {
      return;
    }    

    try {
      if (this.state.note.attachment) {
        if (this.state.note.attachment) {
          s3Delete(this.state.note.attachment)
        }        
      }
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Notes">
        {this.state.note &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="content">
              <FormControl
                onChange={this.handleChange}
                value={this.state.content}
                componentClass="textarea"
              />
            </FormGroup>
            
            {this.state.note.attachment &&
              <FormGroup>
                <ControlLabel>Attachment</ControlLabel>
                <FormControl.Static>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.attachmentURL}
                  >
                    {this.formatFilename(this.state.note.attachment)}
                  </a>
                </FormControl.Static>
              </FormGroup>}
            <FormGroup controlId="file">
              {!this.state.note.attachment &&
                <ControlLabel>Attachment</ControlLabel>}
              <FormControl onChange={this.handleFileChange} type="file" />
            </FormGroup>
            <LoaderButton
              block
              variant="light"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Update"
              loadingText=""
              tile={<FcDataBackup size={30} className="ddb-tile"/>}
            />
            <CalendarButton
              block
              variant="light"
              bsSize="large"
              disabled={!this.validateForm()}
              type="button"
              linkToCalendar={this.state.linkToCalendar}
              onClick={this.handleLinkToCalendar}
              event={this.calendarEvent()}
            />
            {/* <ShareButton
              block
              variant="light"
              bsSize="large"
              disabled={!this.validateForm()}
              type="button"
              shareNote={this.state.shareNote}
              onClick={this.handleShareNote}
              noteUrl={window.location.href}
            /> */}
            <LoaderButton
              block
              variant="light"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Complete"
              loadingText=""
              tile={<FcDeleteDatabase size={30} className="ddb-tile"/>}
            />
          </form>}
      </div>
    );
  }
}
